import React, { useEffect } from "react";
import "./Services.css";
import { TiCodeOutline } from "react-icons/ti";
import { AiOutlineHighlight } from "react-icons/ai";
import { AiOutlineDesktop } from "react-icons/ai";

import aos from "aos";
import "aos/dist/aos.css";

function Services() {
  useEffect(() => {
    aos.init({ duration: 2000 });
  });
  let info = [
    {
      icon: <TiCodeOutline />,
      title: "Front End Developer",
      description:
        "I can covet your psd or figma project to a dynamic web site. Also, I can work With team to achieve common goals.",
      number: "20+",
      meaning: "Project Completed",
      duration: "2000",
    },
    {
      icon: <AiOutlineHighlight />,
      title: "UI & UX Designer",
      description:
        "I can Designe Web sites by using Figma or Adobe Photoshop for more information DM Me",
      number: "2 years",
      meaning: "Of Experience",
      duration: "2500",
    },
    {
      icon: <AiOutlineDesktop />,
      title: "Graphique Designer",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nobis, accusantium?adioashyfidsj",
      number: "5+",
      meaning: "Satiesfied Client",
      duration: "3000",
    },
  ];

  return (
    <div className="services" id="Services">
      <div className="container">
        <div data-aos="fade-right" className="main-heading">
          My Services
        </div>
        <div className="service-container row">
          {info.map(function ({
            icon,
            title,
            description,
            number,
            meaning,
            duration,
          }) {
            return (
              <div
                data-aos="fade-right"
                data-aos-duration={duration}
                className="service-card col-md-6 col-lg-4 col-sm-12"
              >
                <i className="icon">{icon}</i>
                <h1>{title}</h1>
                <p>{description}</p>
                <div className="service-footer">
                  <span>{number}</span>
                  <h4>{meaning}</h4>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Services;
