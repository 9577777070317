/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import "./Contact.css";

import aos from "aos";
import "aos/dist/aos.css";

function Contact() {
  useEffect(() => {
    aos.init({ duration: 2000 });
  });
  function socialmedia() {
    window.open("https://www.instagram.com/ahmed.dev13/", "_blank");
  }
  return (
    <div className="contact" id="Contact">
      <div className="container">
        <h4 data-aos="fade-right" data-aos-duration="2000">
          Contact US
        </h4>
        <p data-aos="fade-right" data-aos-duration="2500">
          Interested Working <span>With me? Let's connect!</span>
        </p>
        <div data-aos="fade-right" data-aos-duration="3000" className="email">
          <a href="mailto:ahmedzeintsu@gmail.com">ahmedzeintsu@gmail.com</a>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="3500"
          className="lets-talk"
        >
          <a href="#" onClick={socialmedia} className="letstalk">
            let's talk
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
