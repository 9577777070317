import React, { useEffect } from "react";
import "./Gallery.css";
import "./filter";
import aos from "aos";
import "aos/dist/aos.css";

function Gallery() {
  useEffect(() => {
    aos.init({ duration: 2000 });
  });
  let websitesdata = [
    {
      dataAos: "fade-up",
      dataAosDuration: "2000",
      link: "https://zeintsutheprogrammer.github.io/first_github_project/",
      source: "../../Assests/01.webp",
    },
    {
      dataAos: "fade-up",
      dataAosDuration: "2500",
      link: "https://zeintsutheprogrammer.github.io/second_github_project/",
      source: "../../Assests/02.webp",
    },
    {
      dataAos: "fade-up",
      dataAosDuration: "3000",
      link: "https://zeintsutheprogrammer.github.io/prsonnelweb_dev/",
      source: "../../Assests/03.webp",
    },
    {
      dataAos: "fade-up",
      dataAosDuration: "3000",
      link: "https://zeintsutheprogrammer.github.io/third_github_project/",
      source: "../../Assests/04.webp",
    },
    {
      dataAos: "fade-up",
      dataAosDuration: "3000",
      link: "https://zeintsutheprogrammer.github.io/appdownload/",
      source: "../../Assests/05.webp",
    },
    {
      dataAos: "fade-up",
      dataAosDuration: "3000",
      link: "https://zeintsutheprogrammer.github.io/soufianeelbakkali/",
      source: "../../Assests/06.webp",
    },
    // {
    //   dataAos: "fade-up",
    //   dataAosDuration: "3000",
    //   link: "www.facebook.com",
    //   source: "../../Assests/05.jpg",
    // },
  ];

  return (
    <div className="gallery" id="Gallery">
      <div className="container">
        <div data-aos="fade-right" className="main-heading">
          Featured Work
        </div>
        {/* <div className="links">
          <ul>
            <li>
              <a data-aos="fade-up" onClick={websites} href="#home">
                Web Sites
              </a>
            </li>
            <li>
              <a
                data-aos="fade-up"
                data-aos-duration="2500"
                onClick={userInterface}
                href="#home"
              >
                User Interface
              </a>
            </li>
          </ul>
        </div> */}
        <div className="pics holder">
          <div className="websites row">
            {websitesdata.map(function ({
              dataAos,
              dataAosDuration,
              link,
              source,
            }) {
              return (
                <a
                  href={link}
                  className="col-lg-4 col-md-6 col-sm-12"
                  target="blank"
                >
                  <img
                    data-aos={dataAos}
                    data-aos-duration={dataAosDuration}
                    src={source}
                    alt="img"
                  ></img>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
