import React, { useEffect } from "react";
import "./About.css";

import aos from "aos";
import "aos/dist/aos.css";

function About() {
  useEffect(() => {
    aos.init({ duration: 2000 });
  });

  return (
    <div className="about" id="About">
      <div className="container row ">
        <div data-aos="fade-up" className="experience col-md-6 col-sm-12">
          <h6>About Me</h6>
          <h1 className="title">
            Visual Design With 2 Years Of Experience. I offer a variety of
            Digital Services
          </h1>
          <p className="description">
            If you are looking for a skilled front-end developer with expertise
            in ReactJS, I am the right person for the job. Let's work together
            to create amazing web applications that exceed your expectations.
          </p>
          <a
            href="../../Assests/AhmedAddyResume.pdf"
            download
            className="button"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            Download My CV
          </a>
        </div>
        <div data-aos="fade-up" className="skills col-md-6 col-sm-12">
          <h1 className="title">The Best Of Digital Experiense</h1>
          <p className="description">
            My skills in HTML, CSS, and JavaScript, combined with my knowledge
            of ReactJS, enable me to create dynamic and interactive user
            interfaces.
          </p>
          <div className="progress-skills">
            <span>HTML</span>
            <div className="progress">
              <div
                data-aos="fade-right"
                data-aos-duration="2000"
                className="progress-bar "
                style={{ width: "80%" }}
              >
                80%
              </div>
            </div>
          </div>
          <div className="progress-skills">
            <span>CSS/BOOTSTRAP</span>
            <div className="progress">
              <div
                data-aos="fade-right"
                data-aos-duration="2500"
                className="progress-bar "
                style={{ width: "75%" }}
              >
                75%
              </div>
            </div>
          </div>
          <div className="progress-skills">
            <span>JavaScript</span>
            <div className="progress">
              <div
                data-aos="fade-right"
                data-aos-duration="3000"
                className="progress-bar "
                style={{ width: "78%" }}
              >
                78%
              </div>
            </div>
          </div>
          <div className="progress-skills">
            <span>ReactJS</span>
            <div className="progress">
              <div
                data-aos="fade-right"
                data-aos-duration="3500"
                className="progress-bar "
                style={{ width: "65%" }}
              >
                65%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
