import React from "react";
import "./App.css";
import NavBar from "./componants/NavBar/NavBar";
import Home from "./componants/Home/Home";
import Services from "./componants/Services/Services";
import Contact from "./componants/Contact/Contact";
import Footer from "./componants/Footer/Footer";
import About from "./componants/About/About";
import Gallery from "./componants/Gallery/Gallery";
import Testimonials from "./componants/testimonials/Testimonials";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "react-bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Home />
      <Services />
      <About />
      <Gallery />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
