import React, { useEffect } from "react";
import "./Home.css";
// import homeimg from "../../Assests/ahmedaddy.png";

import aos from "aos";
import "aos/dist/aos.css";

function Home() {
  useEffect(() => {
    aos.init({ duration: 2000 });
  });

  function socialmedia() {
    window.open("https://www.instagram.com/ahmed_dev13/", "_blank");
  }

  return (
    <div className="home" id="Home">
      <div className="container">
        <div data-aos="fade-up" className="homeText">
          <h1>
            Visual Designer<span>&Developer</span>
          </h1>
          <p>
            Hi, I am a front-end developer with expertise in ReactJS. I have a
            passion for creating user-friendly and responsive web applications
            that provide an excellent user experience.
          </p>
          <button href="#" onClick={socialmedia} className="letstalk">
            Lets Talk
          </button>
        </div>
        <div data-aos="fade-up" className="image">
          <img src="../../Assests/ahmedaddy.png" alt="Home Pic" />
        </div>
      </div>
    </div>
  );
}

export default Home;
