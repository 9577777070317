import React, { useEffect } from "react";
import "./Testimonials.css";
import { VscQuote, VscStarFull } from "react-icons/vsc";
// import testimoialspic01 from "../../Assests/testimonials.jpg";
import { FaRegStar } from "react-icons/fa";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import aos from "aos";
import "aos/dist/aos.css";

const Testimonials = () => {
  useEffect(() => {
    aos.init({ duration: 2000 });
  });
  const Data = [
    {
      pic: "testimoialspic01",
      name: "Raymond C.",
      work: "Spotify Manager",
      paragraphe:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis velit totam fugiat corporis magni mollitia. Reiciendis, laudantium rerum. Magni praesentium dicta hic qui? Quisquam molestias eaque in eos saepe sed?",
      duration: "2500",
      star1: <VscStarFull />,
      star2: <VscStarFull />,
      star3: <VscStarFull />,
      star4: <VscStarFull />,
      star5: <VscStarFull />,
    },
    {
      pic: "testimoialspic01",
      name: "Ahmed.dev13",
      work: "Spotify Manager",
      paragraphe:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis velit totam fugiat corporis magni mollitia. Reiciendis, laudantium rerum. Magni praesentium dicta hic qui? Quisquam molestias eaque in eos saepe sed?",
      duration: "3000",
      star1: <VscStarFull />,
      star2: <VscStarFull />,
      star3: <VscStarFull />,
      star4: <VscStarFull />,
      star5: <FaRegStar />,
    },
    {
      pic: "testimoialspic01",
      name: "Abdo Rahim",
      work: "Spotify Manager",
      paragraphe:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis velit totam fugiat corporis magni mollitia. Reiciendis, laudantium rerum. Magni praesentium dicta hic qui? Quisquam molestias eaque in eos saepe sed?",
      duration: "2500",
      star1: <VscStarFull />,
      star2: <VscStarFull />,
      star3: <VscStarFull />,
      star4: <FaRegStar />,
      star5: <FaRegStar />,
    },
    {
      pic: "testimoialspic01",
      name: "walid",
      work: "Spotify Manager",
      paragraphe:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis velit totam fugiat corporis magni mollitia. Reiciendis, laudantium rerum. Magni praesentium dicta hic qui? Quisquam molestias eaque in eos saepe sed?",
      duration: "3000",
      star1: <VscStarFull />,
      star2: <VscStarFull />,
      star3: <VscStarFull />,
      star4: <VscStarFull />,
      star5: <VscStarFull />,
    },
    {
      pic: "testimoialspic01",
      name: "Oussama",
      work: "Spotify Manager",
      paragraphe:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis velit totam fugiat corporis magni mollitia. Reiciendis, laudantium rerum. Magni praesentium dicta hic qui? Quisquam molestias eaque in eos saepe sed?",
      duration: "2500",
      star1: <VscStarFull />,
      star2: <VscStarFull />,
      star3: <VscStarFull />,
      star4: <VscStarFull />,
      star5: <FaRegStar />,
    },
    {
      pic: "testimoialspic01",
      name: "Achraf",
      work: "Spotify Manager",
      paragraphe:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis velit totam fugiat corporis magni mollitia. Reiciendis, laudantium rerum. Magni praesentium dicta hic qui? Quisquam molestias eaque in eos saepe sed?",
      duration: "3000",
      star1: <VscStarFull />,
      star2: <VscStarFull />,
      star3: <VscStarFull />,
      star4: <VscStarFull />,
      star5: <FaRegStar />,
    },
  ];
  // const swiper = useSwiper();

  return (
    <div className="testimonials" id="Testimonials">
      <div className="container">
        <div data-aos="fade-right" className="main-heading">
          Client Say
        </div>
        <div className="cards-container ">
          {/* <div className="slidebtn">
            <button onClick={() => swiper.slidePrev}>&lt;</button>
            <button onClick={() => swiper.slideNext()}>&gt;</button>
          </div> */}
          <div data-aos="fade-left" className="exemple">
            <span>Exemple (you can swipe)</span>
          </div>
          <Swiper spaceBetween={50} slidesPerView={1}>
            {Data.map(function ({
              pic,
              name,
              work,
              paragraphe,
              duration,
              star1,
              star2,
              star3,
              star4,
              star5,
            }) {
              return (
                <SwiperSlide>
                  <div
                    data-aos="fade-up"
                    data-aos-duration={duration}
                    className="t--card "
                  >
                    <div className="header">
                      <VscQuote className="icon" />
                      <img
                        src="../../Assests/testimonials.webp"
                        alt="testimonials"
                      />
                      <div className="name">
                        {name}
                        <span>{work}</span>
                      </div>
                    </div>
                    <p>{paragraphe}</p>
                    <div className="stars">
                      <i>{star1}</i>
                      <i>{star2}</i>
                      <i>{star3}</i>
                      <i>{star4}</i>
                      <i>{star5}</i>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default Testimonials;
