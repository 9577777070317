import React from "react";
import "./NavBar.css";
import { FaOptinMonster } from "react-icons/fa";
import { TiThSmall } from "react-icons/ti";
import { VscChromeClose } from "react-icons/vsc";

function NavBar() {
  // function openandclose() {
  //   let navBar = document.querySelector(".navbar .navbarLinks ul");
  //   let closeBtn = document.querySelector(".closeBtn");
  //   navBar.style.display = "block";
  //   closeBtn.style.display = "block";
  //   closeBtn.addEventListener("click", () => {
  //     closeBtn.style.display = "none";
  //     navBar.style.display = "none";
  //   });
  // }

  // function openenavbar() {
  //   let navbar = document.querySelector(".navbarLinks ul");
  //   let closebtn = document.querySelector(".closeBtn");
  //   closebtn.style.display = "block";
  //   navbar.style.display = "block";
  // }
  // function closenavbar() {
  //   let navbar = document.querySelector(".navbarLinks ul");
  //   let closebtn = document.querySelector(".closeBtn");
  //   navbar.style.display = "none";
  //   closebtn.style.display = "none";
  // }
  function openenavbar() {
    let navbar = document.querySelector(".navbarLinks ul");
    let closebtn = document.querySelector(".closeBtn");

    let link = document.querySelector(".navbarLinks ul li a");

    closebtn.style.opacity = "1";
    navbar.style.opacity = "1";
    navbar.style.top = "72px";
    closebtn.style.top = "80px";
    navbar.style.zIndex = "1000";
    link.addEventListener("click", () => {
      navbar.style.opacity = "0";
      closebtn.style.opacity = "0";
    });
  }
  function closenavbar() {
    let navbar = document.querySelector(".navbarLinks ul");
    let closebtn = document.querySelector(".closeBtn");
    navbar.style.opacity = "0";
    closebtn.style.opacity = "0";
    navbar.style.top = "0";
    navbar.style.zIndex = "-1000";
    closebtn.style.top = "0";
  }

  return (
    <div className="navbar ">
      <div className="container">
        <div className="navLogo">
          <FaOptinMonster className="LOGO" />
          <span>Ahmed</span>
        </div>
        <div className="navbarLinks">
          <ul>
            <li>
              <a href="#Home" className="active">
                Home
              </a>
            </li>
            <li>
              <a href="#Services">Services</a>
            </li>
            <li>
              <a href="#About">About</a>
            </li>
            <li>
              <a href="#Gallery">Gallery</a>
            </li>
            <li>
              <a href="#Testimonials">Testimonials</a>
            </li>
            <li>
              <a href="#Contact">Contact</a>
            </li>
          </ul>
        </div>
        <TiThSmall onClick={openenavbar} className="tith-icon" />
      </div>
      <VscChromeClose
        style={{ cursor: "pointer" }}
        onClick={closenavbar}
        className="closeBtn"
      />
    </div>
  );
}

export default NavBar;
